<template>
  <div>
    <img alt="Vue logo" src="../assets/logo.png" />

    <h4>Welcome to Thrifty Pig!</h4>
    <br />
    <p>
      {{ showSignIn }} We are all about YOUR success in managing your budget.
    </p>
    <p>
      How many times have you sat down and completed a budget, only to find that
      a week later your plan is working? And then you have to rework your budget
      to be able to catchup - or many people just give up.
    </p>
    <h3>The Answer</h3>
    <p>
      Thrifty Pig provides you with a way of managing your budget so that you
      have a greater chance of success.
    </p>
    <p>
      Yes we help you plan your expenses and capture your sources of income, but
      that's only part of the answer. We'll also help you to organise how you
      automate the payment of those expenses and help you ensure you have the
      right amount of money saved when you need to pay for those longer term
      expenses like car rego.
    </p>
    <p>
      All you need to make the system work for you is a few minutes to capture
      the details of what you earn and spend, and we'll help you setup the right
      automated strucure to get you on the path to success.
    </p>
    <p>
      For more details checkout our
      <router-link class="primary-colour-text" to="/about">About</router-link>
      page and when you are ready
      <b-button variant="link" @click="toggleSignIn"
        >register and login</b-button
      >
      to get started
    </p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Home',
  computed: {
    ...mapGetters(['authState', 'showSignIn']),
  },
  methods: {
    ...mapActions['setShowSignInState'],
    async toggleSignIn() {
      return await this.$store.dispatch(
        'setShowSignInState',
        !this.showSignIn && this.authState !== 'signIn',
      );
    },
  },
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #f2b1af !important;
}

b-button,
.btn-link {
  color: #f2b1af !important;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}
</style>
